let shopApi={}
shopApi.cartUpdateTimer=undefined;

shopApi.addToCart=(articleNumber, amount=1, updateCartCounter=true)=>{
    return new Promise((resolve,reject)=>{
         $.get(`/api/cart/add?article=${articleNumber}&amount=${amount}`, function(data){
            resolve(data);
            if(updateCartCounter){
                shopApi.updateCartCounter();
            }
        }).fail(()=>{
            reject();
        });
    });
};

shopApi.updateCartPosition=(snr, amount, pos16511=-1, reload=true)=>{
    return new Promise((resolve,reject)=>{
        $.get(`/api/cart/update?snr=${snr}&amount=${amount}&pos16511=${pos16511}`, function(data){
            resolve(data);
            if(reload) {
                window.location.reload();
            }
        }).fail((data)=>{
            reject(data);
        });
    });
}

shopApi.deleteCartPosition=(snr, reload=true)=>{
    return new Promise((resolve,reject)=>{
        $.get(`/api/cart/delete?snr=${snr}`, function(data){
            resolve(data);
            if(reload) {
                window.location.reload();
            }
            if(updateCartCounter){
                shopApi.updateCartCounter();
            }
        }).fail((data)=>{
            reject(data);
        });
    });
}

shopApi.clearCart=(reload=true)=>{
    return new Promise((resolve,reject)=>{
        $.get(`/api/cart/clear`, function(data){
            resolve(data);
            if(reload) {
                window.location.reload();
            }
            if(reload) {
                window.location.reload();
            }
        }).fail((data)=>{
            reject(data);
        });
    });
}

shopApi.getCartCount=()=>{
    return new Promise((resolve,reject)=>{
        $.get(`/api/cart/getcount`, function(data){
            resolve(data.data);
        }).fail((data)=>{
            reject(data);
        })
    });
};

shopApi.updateCartCounter=()=>{
    if(shopApi.cartUpdateTimer){
        clearTimeout(shopApi.cartUpdateTimer);
        shopApi.cartUpdateTimer=undefined;
    }
    shopApi.cartUpdateTimer=setTimeout(()=>{
        var cartCounter=document.getElementById("count-wk");
        if(cartCounter) {
            shopApi.getCartCount().then((data)=>{
                if(data){
                    cartCounter.innerHTML=data;
                }
            });
        }
        var respcartCounter=document.getElementById("resp-count-wk");
        if(wkitems) {
            shopApi.getCartCount().then((data)=>{
                if(data){
                    respcartCounter.innerHTML=data;
                }
            });
        }
        var wkitems=document.getElementById("wk-items");
        if(wkitems) {
            shopApi.getCartCount().then((data)=>{
                if(data){
                    wkitems.innerHTML=data;
                }
            });
        }
        shopApi.cartUpdateTimer=undefined;
    }, 1000);
   
}

shopApi.updateBeleg=(beleg)=>{
    return new Promise((resolve,reject)=>{
        $.ajax({
            type:"POST",
            url:"/api/beleg/update",
            contentType:"application/json",
            data: JSON.stringify(beleg),
            success:function(data){resolve(data);},
            error:function(data){reject(data)}
        });
    })
}

shopApi.createDeliveryAddress=(deliveryAddress)=>{
    return new Promise((resolve,reject)=>{
        $.ajax({
            type:"POST",
            url:"/api/deliveryAddress/create",
            contentType:"application/json",
            data: JSON.stringify(deliveryAddress),
            success:function(data){resolve(data);},
            error:function(data){reject(data)}
        });
    })
}

shopApi.updateDeliveryAddress=(deliveryAddress)=>{
    return new Promise((resolve,reject)=>{
        $.ajax({
            type:"POST",
            url:"/api/deliveryAddress/update",
            contentType:"application/json",
            data: JSON.stringify(deliveryAddress),
            success:function(data){resolve(data);},
            error:function(data){reject(data)}
        });
    })
}

shopApi.updateProfile=(profile)=>{
    return new Promise((resolve,reject)=>{
        $.ajax({
            type:"POST",
            url:"/api/profile/update",
            contentType:"application/json",
            data: JSON.stringify(profile),
            success:function(data){resolve(data);},
            error:function(data){reject(data)}
        })
    });
}

shopApi.loadRenderedOrders=(belegart="", startDate, endDate)=>{
    return new Promise((resolve,reject)=>{
        $.get(`./orderslist?belegart=${belegart}&startDate=${startDate}&endDate=${endDate}`, function(data){
            resolve(data);
        }).fail((data)=>{
            reject(data);
        });
    });
}

shopApi.loadRenderedOrderPositions=(belegindx)=>{
    return new Promise((resolve,reject)=>{
        $.get(`./orderposisions?belegindex=${belegindx}`, function(data){
            resolve(data);
        }).fail((data)=>{
            reject(data);
        });
    });
}

shopApi.loadRenderdOrderSummary=()=>{
    return new Promise((resolve,reject)=>{
        $.get(`./ordersummary`, function(data){
            resolve(data);
        }).fail((data)=>{
            reject(data);
        })
    });
}

shopApi.finishOrder=(beleg)=>{
    return new Promise((resolve,reject)=>{
        $.ajax({
            type:"POST",
            url:"./order/finish",
            contentType:"application/json",
            data: JSON.stringify(beleg),
            success:function(data){resolve(data);},
            error:function(data){reject(data)}
        })
    });
}

export default shopApi;