const utils={
    inputToObject(target,containerQuery,inputQuery){
        let elContainer=$(containerQuery);
        if(elContainer){
            let children=elContainer.find(inputQuery);
            if(children){
                children.each(key=>{
                    target[children[key].id]=children[key].value;
                });
            }
        }
    },

    objectToInput(object, containerQuery, inputQuery, notfoundValue=""){
        let elContainer=$(containerQuery);
        if(elContainer){
            let children=elContainer.find(inputQuery);
            if(children){
                children.each(key=>{
                    if(object[children[key].id]){
                        children[key].value=object[children[key].id];
                    } else {
                        children[key].value=notfoundValue;
                    }
                });
            }
        }
    },

    objectToElement(object, containerQuery, query){
        let children=$(containerQuery).find(query);
        if(children) {
            children.each(key => {
                if(typeof object[children[key].id] !=='undefined') {
                    $(children[key]).html(object[children[key].id]);
                }
            });
        }
    },

    showPasswordReadable(pwdElementId){
        let element=document.getElementById(pwdElementId);
        if(element){
            element.type="text";
        }
    },

    showPasswordUneadable(pwdElementId){
        let element=document.getElementById(pwdElementId);
        if(element){
            element.type="password";
        }
    },

    validate(form){
        if(form){
            if(!form.reportValidity()){
                var tmpSubmit = document.createElement('button')
                form.appendChild(tmpSubmit)
                tmpSubmit.click()
                form.removeChild(tmpSubmit)
                return false;
            } else {
                return true;
            }

        } else {
            return false;
        }
    }
}


export default utils;