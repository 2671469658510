var checkoutRootElementsIds=["root_checkout_0", "root_checkout_1", "root_checkout_2"]

const checkout = {
    showCheckoutStep(showStep){
        for(var i=0;i<checkoutRootElementsIds.length;i++){
            let element=document.getElementById(checkoutRootElementsIds[i]);
            let elementBreadcrumb=document.getElementById("breadcrumb_checkout_"+i);
            if(i==showStep){
                element.style.display="flex";
            } else {
                element.style.display="none";
            }
    
            if(i<=showStep){
                elementBreadcrumb.classList.add("active");
                if(i<showStep){
                    for(const child of elementBreadcrumb.children){
                        if(child.tagName.toUpperCase()=="SPAN"){
                            //child.style.color="var(--white-color)";
                        }
                    }
                }
    
            } else {
                elementBreadcrumb.classList.remove("active");
            }
            //console.log(showStep);
        }
    },

    checkoutNavClick(showStep){
        let elementBreadcrumb=document.getElementById("breadcrumb_checkout_"+showStep);
        if(elementBreadcrumb.classList.contains("active")){
            checkout.showCheckoutStep(showStep);
        }
    },
    
    setDeliveryAddressVisible(event){
        if(!document.getElementById("lieferadresse_question").checked){
            utils.objectToInput({},'#edit_adr','input[id^=LFA]');
            utils.objectToInput({},'#edit_adr','select[id^=LFA]');
            document.getElementById("select_lieferaddresse").value="choose";
        }
        $(".abweichende_lieferadresse").toggle();
    },
    
    checkoutSendAndPay() {
        let form=document.getElementById("form_rechnungsadresse");
        if(utils.validate(form)){
            let errorMsg=document.getElementById("error-rechnungsadresse")
            if(errorMsg){
                errorMsg.style.display="none"
            }
            utils.inputToObject(beleg,'#rechnungsadresse','input[id^=BEL]')
            var proms=[];
            showWait();
            shopApi.updateBeleg(beleg).then(data=>{
                checkout.saveDeliveryAddress().then(resp=>{
                    hideWait();
                    if(resp.success&&resp.data){
                        document.getElementById("error-root").style.display="none";
                        beleg=JSON.parse(resp.data);
                        let errors=$('#error-root').find('[id^=error_]');
                        if(errors){
                            //console.log(errors);
                            errors.each(key=>{
                                errors[key].style.display="none";
                            });
                        }
                        checkout.showCheckoutStep(1);
                    } else if(!resp.success){
                        document.getElementById("error-root").style.display="block";
                        let msg=resp.message;
                        if(resp.message3){
                            msg=resp.message3
                        } else if(resp.message2){
                            msg=resp.message2
                        }
        
                        document.getElementById(msg).style.display="block";
                    }
                    
                    
                }, (error)=>{
                    hideWait();
                    if(error!="INVALID_FORM"){
                        window.alert("Fehler beim Speicher");
                    }
                })
            });
        } else {
            let errorMsg=document.getElementById("error-rechnungsadresse");
            if(errorMsg){
                errorMsg.style.display="flex"
            }
        }
    },
    
    resolveCountry(elementId, value, notfound=""){
        let deliveryAdrCountry=document.getElementById(elementId);
        if(deliveryAdrCountry){
            let found=false;
            for(var i=0;i<countries.length;i++){
                if(countries[i].DBK_0_60 == value){
                    deliveryAdrCountry.innerHTML=countries[i].DBK_180_60;
                    found=true;
                    break;
                }
            }
    
            if(!found){
                deliveryAdrCountry.innerHTML=notfound;
            }
        }
    },
    
    checkoutCheckAndOrder() {
        showWait();
        utils.objectToElement(beleg,'#root_checkout_2','[id^=BEL]')
        checkout.resolveCountry("READR_COUNTRY_BEL_1789_3",beleg.BEL_1789_3);
        checkout.resolveCountry("DELADR_COUNTRY_BEL_4256_3",beleg.BEL_4256_3);
        checkout.resolveCountry("DELADR_COUNTRY_BEL_4256_3",beleg.BEL_4256_3);
        checkout.resolveCountry("DELARD_COUNTRY_BEL_1789_3",beleg.BEL_1789_3);
    
        let deliveryTypes=$('[id^=deliveryType]');
        deliveryTypes.each(key=>{
            if(deliveryTypes[key].checked){
                let deliveryTypeId=deliveryTypes[key].value;
                beleg["BEL_552_2"]=deliveryTypeId;
            }
        })

        shopApi.updateBeleg(beleg).then(resp=>{
            if(resp.success&&resp.data){
                document.getElementById("error-root").style.display="none";
                beleg=JSON.parse(resp.data);
            }

            shopApi.loadRenderdOrderSummary().then(content=>{
                document.getElementById("order_step_3").innerHTML=content;
                checkout.showCheckoutStep(2);    
                hideWait();
            }, (error)=>{
                window.alert(error);
                hideWait();
            })
        });

    },

    orderNow(){
        showWait();
        utils.inputToObject(beleg,'#root_checkout_2','input[id^=BEL]')
        shopApi.finishOrder(beleg).then(data=>{
            window.location="/de/order/finish";
        })
    },
    
    saveDeliveryAddress(){
        let useDifferentDeliveryAdr=document.getElementById("lieferadresse_question").checked;
        var cbAdr=$('#select_lieferaddresse');
        if(useDifferentDeliveryAdr&&cbAdr&&cbAdr.length>0){
            let form=document.getElementById("form_lieferadresse");
            if(utils.validate(form)){
                let errorMsg=document.getElementById("error-lieferadresse")
                if(errorMsg){
                    errorMsg.style.display="none"
                }
            
                if(cbAdr[0].selectedIndex==1||cbAdr[0].selectedIndex==0){
                    let deliveryAddress={LFA_2_8:"-1"};
                    utils.inputToObject(deliveryAddress,'#edit_adr','input[id^=LFA]')
                    utils.inputToObject(deliveryAddress,'#edit_adr','select[id^=LFA]')
                    return shopApi.createDeliveryAddress(deliveryAddress);
                } else if (cbAdr[0].selectedIndex>1){
                    let id=cbAdr.find(":selected").val();
                    let deliveryAddress=jQuery.extend({},checkout.getDeliveryAddressById(id));
                    utils.inputToObject(deliveryAddress,'#edit_adr','input[id^=LFA]')
                    utils.inputToObject(deliveryAddress,'#edit_adr','select[id^=LFA]')
                    deliveryAddress["LFA_2_8"]=id;
                    deliveryAddress["LFA_1077_40"]=deliveryAddress["LFA_120_30"];
                    deliveryAddress["LFA_1031_46"]=deliveryAddress["LFA_80_30"];
                    delete deliveryAddress.id;
                    return shopApi.updateDeliveryAddress(deliveryAddress);
                }
            } else {
                let errorMsg=document.getElementById("error-lieferadresse")
                if(errorMsg){
                    errorMsg.style.display="flex"
                }
                return new Promise((resolve,reject)=>{
                    reject("INVALID_FORM");
                });
            }
        } else {
            let errorMsg=document.getElementById("error-lieferadresse")
            if(errorMsg){
                errorMsg.style.display="none"
            }
            return shopApi.updateDeliveryAddress({id:"-1"});
        }

    },
    
    getDeliveryAddressById(id){
        for(var i=0;i<deliveryAddresses.length;i++){
            if(deliveryAddresses[i].id==id){
                return deliveryAddresses[i];
            }
        }
    },
    
    fillDeliveryAddress(id){
        if(id=="newDeliveryAddress"){
            utils.objectToInput({},'#edit_adr','input[id^=LFA]')
            utils.objectToInput({},'#edit_adr','select[id^=LFA]')
        } else {
            if(deliveryAddresses){
                utils.objectToInput(checkout.getDeliveryAddressById(id),'#edit_adr','input[id^=LFA]')
                utils.objectToInput(checkout.getDeliveryAddressById(id),'#edit_adr','select[id^=LFA]')
            }
        }
    },
    
    acceptAgbandDSGVO(){
        let agb=document.getElementById("agb");
        let dsgvo=document.getElementById("privacy");
        let orderButton=document.getElementById("btn_order");
    
        let checkAgb=false;
        if(agb){
            checkAgb=agb.checked;
        }
    
        let checkDsgvo=false;
        if(dsgvo){
            checkDsgvo=dsgvo.checked;
        }
    
        if(orderButton){
            orderButton.disabled=!(checkAgb&&checkDsgvo);
        }
    },

    checkIfReferenzIsActive(){
        //console.log("Referenz Ausgeführt");
        let referenz=document.getElementById("BEL_4691_200");
        referenz.addEventListener("focusin", function () {
            var label = this.parentElement.querySelector("label");
            if (label) {
                label.classList.add("active");
            }
        });
        referenz.addEventListener("focusout", function () {
            if (!this.value) {
                var label = this.parentElement.querySelector("label");
                if (label) {
                    label.classList.remove("active");
                }
            }
        });
    },

    checkIfBemerkungIsActive(){
        //console.log("Bemerkung Ausgeführt");
        let bemerkung=document.getElementById("BEL_5632_200");
        bemerkung.addEventListener("focusin", function () {
            var label = this.parentElement.querySelector("label");
            if (label) {
                label.classList.add("active");
            }
        });
        bemerkung.addEventListener("focusout", function () {
            if (!this.value) {
                var label = this.parentElement.querySelector("label");
                if (label) {
                    label.classList.remove("active");
                }
            }
        });
    },

}


export default checkout;