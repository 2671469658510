import $ from "jquery";
import "jquery-ui";
import * as bootstrap from "bootstrap";
import * as CookieConsent from "vanilla-cookieconsent";
import "slick-carousel";

//SHOP FONTS
import "@fontsource/roboto";

//SHOP CSS
import "./bootstrap.css";
import "../node_modules/vanilla-cookieconsent/dist/cookieconsent.css";
import "./all.css";
import "./slick.css";
import "./slick-theme.css";
import "./fontawesomeall.css";
import "../node_modules/owl.carousel.es6/dist/assets/owl.carousel.css";
import "../node_modules/owl.carousel.es6/dist/assets/owl.theme.default.css";

//SHOP RESPONSIVE CSS
import "./responsive.css";

//SHOP JS
import "../node_modules/vanilla-cookieconsent/dist/cookieconsent.esm.js";
import "slick/index.js";
import "owl.carousel.es6";
import shopApi from "./shopApi.js";
import checkout from "./checkout.js";
import order from "./orders.js";
import profile from "./profile.js";
import utils from "./utils.js";

import { createPopper } from "@popperjs/core";

window.Popper = require("@popperjs/core");
window.shopApi=shopApi;
window.checkout=checkout;
window.order=order;
window.profile=profile;
window.utils=utils;

window.hideWait=()=>{
  let wait=document.getElementById("search-loading");
  if(wait){
    wait.parentNode.removeChild(wait);
  }
}

window.showWait=()=>{
  let waitRoot=document.createElement('div');
  waitRoot.id="search-loading";
 
  let waitBox=document.createElement('div');
  waitBox.classList.add("box");
  
  let waitImg=document.createElement('div');
  waitImg.classList.add("lds-ring");
  //Childs needed for animation
  waitImg.appendChild(document.createElement('div'));
  waitImg.appendChild(document.createElement('div'));
  waitImg.appendChild(document.createElement('div'));
  waitImg.appendChild(document.createElement('div'));

  let waittxt=document.createElement('div');
  if(document.documentElement.lang="de"){
    waittxt.innerHTML="Bitte warten";
  } else if(document.documentElement.lang="fr"){
    waittxt.innerHTML="S'il vous plaît, attendez";
  } else {
    waittxt.innerHTML="Please wait";
  }
  
  waittxt.classList.add("text");
  
  waitRoot.appendChild(waitBox);
  waitBox.appendChild(waitImg);
  waitBox.appendChild(waittxt);

  console.log(waitRoot.innerHTML);
  document.getElementById("page").appendChild(waitRoot);
}


//CUSTOM JS CODE
window.jQuery = $;
window.$ = $;
window.profile=profile;
window.CookieConsent = CookieConsent;
var pgloadtime = 300;

window.onbeforeunload=(event)=>{
 showWait();
}

//Scroll to Top
//scrolltotopbtn = document.getElementById("scrolltotop"); //Get the button:
// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    scrolltotopbtn.style.display = "block";
  } else {
    scrolltotopbtn.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

//Produktdetails Zoom Function
window.zoom = function zoom(e){
    var zoomer = e.currentTarget;
    let offsetX = 0;
    let offsetY = 0;
    e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
    e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
    let x = offsetX/zoomer.offsetWidth*100
    let y = offsetY/zoomer.offsetHeight*100
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
}

//Scroll to Top
let topBtn = document.querySelector(".top-btn");
topBtn.onclick = () => window.scrollTo({ top: 0, behavior: "smooth" });
window.onscroll = () =>
  window.scrollY > 100
    ? (topBtn.style.opacity = 1)
    : (topBtn.style.opacity = 0);

//Startseite Content Height SEO Text
$("#readmore").click(function () {
  $("#seo-container").animate(
    { height: $("#seo-container").get(0).scrollHeight },
    500
  );
  $("#readmore").css("display", "none");
  $("#seo-container").addClass("nogradient");
  $("#readmore2").css("display", "block");
});


//Startseite Carousel Slider
$(".lazy").slick({
  lazyLoad: "ondemand", // ondemand progressive anticipated
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

//Responsive Change Language 
var $language = $('.offcanvas .languagenavigation'),
$languageTrigger = $('.offcanvas .languagenavigation ul li i');

$languageTrigger.click(function(e) {
  e.preventDefault();
	var $this = $(this);
	$this.toggleClass('active').next('ul').toggleClass('active');
});

//Responsive Navigation
var $menu = $('.offcanvas .responsivenavigation'),
$menuTrigger = $('.offcanvas .responsivenavigation ul li i');

$menuTrigger.click(function(e) {
  e.preventDefault();
	var $this = $(this);
	$this.toggleClass('active').next('ul').toggleClass('active');
});

//Ladegeschwindigkeit Shop
var before_loadtime = new Date().getTime();
window.onload = Pageloadtime;
function Pageloadtime() {
  var aftr_loadtime = new Date().getTime();
  // Time calculating in seconds
  pgloadtime = (aftr_loadtime - before_loadtime) / 1000;
  document.getElementById("loadtime").innerHTML =
    "Seite wurde geladen in " + pgloadtime + " Sekunden.";
  //console.log(pgloadtime);
}

//Count Up Produkt Quantity
$(
  function () {
    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 20,
      nav: true,
      dots: false,
      lazyLoad: true,
      autoHeight: true,
      lazyLoadEager: 2,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 6,
        },
      },
    });
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      fade: true,
      asNavFor: ".slider-nav",
    });
    $(".slider-nav").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: ".slider-for",
      dots: false,
      centerMode: false,
      focusOnSelect: true,
    });

    $(".qtyplus").click(function () {
      var inpt = $(this).parents(".anzahl").find("[name=Menge]");
      var val = parseInt(inpt.val());
      if (val < 0) inpt.val((val = 0));
      inpt.val(val + 1);
    });
    
    $(".qtyminus").click(function () {
      var inpt = $(this).parents(".anzahl").find("[name=Menge]");
      var val = parseInt(inpt.val());
      if (val < 0) inpt.val((val = 0));
      if (val-1 == 0) return;
      inpt.val(val - 1);
      
    });
    
    //Warenkorb Animation
    document.querySelectorAll('.add-to-cart').forEach(button => {
    button.addEventListener('click', e => {
        if(button.id&&button.id.length>8){
          let articleNumber=button.id.substring(8);
          let amount=1;
          let container=button.parentElement.parentElement;
          if(container){
            let amountElement=container.querySelector('#menge');
            if(amountElement) {
              amount=amountElement.value;
            }
          }
          if(amount>0){
            shopApi.addToCart(articleNumber, amount, true).then(()=>{
              button.classList.toggle('added');
            },(error)=>{
              window.alert(error.message);
            })
          }
        } else {
          console.warn(button.id +": Id for button does not follow expected format. It shoud start with add addcart_ followd by article number");
        }
      });
    });

    let panels = $("#services__accordion > .accord > .accordion__body").hide();
    let h3 = $("#services__accordion > .accord:nth-child(1) > h3");

    // Hide all accordion images

    //console.log($("#accordion__img > img").hide());
    var images = $("#accordion__img > img").hide();

    // Show first panel at start
    panels.first().show();
    h3.addClass("active");

    // Show first accordion image at start
    images.first().show();
    //  */
    // On click of a panel title

    $("#services__accordion .accord h3").click(function () {
      // The panel is not the H3, but its parent.
      $("h3.active").removeClass("active");
      $(this).addClass("active");
      let panel = $(this).parent(),
        // The panel has an attribute that will tell me which image
        //  I want to manipulate. This is because I've given the
        //  images ID's to match. Kind of brittle, but it will work.
        tabName = panel.attr("tab-name"),
        image = $("#" + tabName + "-img");

      // Slide Up all other panels
      panels.slideUp();
      images.slideUp();

      // Slide Down target panel, and target image.
      panel.find(".accordion__body").slideDown();
      image.slideDown();

      return false;
    });

    // Registrierung
    $(".registrierung-formular input").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".registrierung-formular input").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });
    $(".registrierung-formular textarea").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".registrierung-formular textarea").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    //change button stuff on click
    $("#a li").click(function () {
      var img = $(this).find("img").attr("src");
      var value = $(this).find("img").attr("value");
      var text = $(this).attr("data-variantname");
      var variantprice = $(this).attr("data-price");
      var option = $(this).attr("data-option");
      if(typeof variantprice !== "undefined") {
      var item =
        '<li><span class="img"><img src="' +
        img +
        '" alt="" value="' +
        value +
        '" loading="lazy" decoding="async"/></span><span class="txt">' +
        text +
        '</span>' +
        '<span class="variant_price">' +
        variantprice +
        ' € / ' +
        '</span>' +
        '<span class="art-nr">' +
        option +
        '</span></li>';
      } else {
      var item =
        '<li><span class="img"><img src="' +
        img +
        '" alt="" value="' +
        value +
        '"/></span><span class="txt">' +
        text +
        '</span>' +
        '<span class="art-nr">' +
        option +
        '</span></li>';  
      }
      $(".btn-select").html($(this));
      $(".btn-select").attr("value", value);
      $(".b").toggle();
      if ($(this).data("url") != "" || $(this).data("url") != "undefined") {
        window.location = $(this).data("url");
      }
    });

    $(".btn-select").click(function () {
      $(".b").toggle();
    });

    //check local storage for the lang
    var sessionLang = localStorage.getItem("lang");
    if (sessionLang) {
      //find an item with value of sessionLang
      var langIndex = langArray.indexOf(sessionLang);
      $(".btn-select").html(langArray[langIndex]);
      $(".btn-select").attr("value", sessionLang);
    } else {
      var langIndex = langArray.indexOf("ch");
      //console.log(langIndex);
      $(".btn-select").html(langArray[langIndex]);
      //$('.btn-select').attr('value', 'en');
    }

    //Produktdetails - Thumnail Img onclick
    $(".img-container img").click(function () {
      $(".big_image img").attr("src",$(this).attr("src").replace("60/60", "400/200"));
      $(".big_image").css("background-image", "url(" + $(this).attr("src") + ")");
      //console.log($(this).attr("src"));
    });

    //
    /*************************************************************************
REGISTRIERUNG - LABEL UND INPUT ANIMATION
*************************************************************************/
    $(".registrierung-formular input").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".registrierung-formular input").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    setTimeout(function () {
      $("#reg .widget").each(function () {
        var $input = $(this).find("input");
        if ($input.val()) {
          $input.parent().find("label").addClass("active");
        }
      });
    }, 100);

    /*************************************************************************
REGISTRIERUNG - CHECK CHECKBOX VALID
*************************************************************************/
    $("#reg input#submit").on("click", function () {
      var $checkbox = $("input#datenschutz");
      if ($checkbox.is(':checked')) {
        $checkbox.parent().removeClass("invalid");
      } else {
        $checkbox.parent().addClass("invalid");
      }
    });

    $("#reg input#datenschutz").on("click", function () {
      var $checkbox = $("input#datenschutz");
      if ($checkbox.is(':checked')) {
        $checkbox.parent().removeClass("invalid");
      } else {
        $checkbox.parent().addClass("invalid");
      }
    });

    /*************************************************************************
REGISTRIERUNG - BESTANDS-UND NEUKUNDEN FELDER
*************************************************************************/
    $('input[name="regoption"]')
      .on("change", function () {
        $(".bestandskunde").toggle(
          +this.value === "bestandskunde" && this.checked
        );
        // Kundennummer = Required wenn als Bestandskunde registrieren
        if (this.value == "neukunde" && this.checked) {
          $('input[name="kundennr"]').prop("required", false);
          $('input[name="kundennr"]').prop("disabled", true);
          if($('input[name="firma"]').data("required") == "1") {
            $('input[name="firma"]').prop("disabled", false);
          }
          if($('input[name="strasse"]').data("required") == "1") {
            $('input[name="strasse"]').prop("disabled", false);
          }
          if($('input[name="hausnummer"]').data("required") == "1") {
            $('input[name="hausnummer"]').prop("disabled", false);
          }
          if($('input[name="plz"]').data("required") == "1") {
            $('input[name="plz"]').prop("disabled", false);
          }
          if($('input[name="stadt"]').data("required") == "1") {
            $('input[name="stadt"]').prop("disabled", false);
          }
          if($('select[name="land"]').data("required") == "1") {
            $('select[name="land"]').prop("disabled", false);
          }
          if($('input[name="ustid"]').data("required") == "1") {
            $('input[name="ustid"]').prop("disabled", false);
          }
          if($('input[name="webseite"]').data("required") == "1") {
            $('input[name="webseite"]').prop("disabled", false);
          }
          $(".bestandskunde").toggle();
          $(".neukunde").toggle();
        } else if (this.value == "bestandskunde" && this.checked) {
          $(".neukunde").toggle();
          $('input[name="kundennr"]').prop("required", true);
          $('input[name="kundennr"]').prop("disabled", false);
          if($('input[name="firma"]').data("required") == "1") {
            $('input[name="firma"]').prop("disabled", true);
          }
          if($('input[name="strasse"]').data("required") == "1") {
            $('input[name="strasse"]').prop("disabled", true);
          }
          if($('input[name="hausnummer"]').data("required") == "1") {
            $('input[name="hausnummer"]').prop("disabled", true);
          }
          if($('input[name="plz"]').data("required") == "1") {
            $('input[name="plz"]').prop("disabled", true);
          }
          if($('input[name="stadt"]').data("required") == "1") {
            $('input[name="stadt"]').prop("disabled", true);
          }
          if($('select[name="land"]').data("required") == "1") {
            $('select[name="land"]').prop("disabled", true);
          }
          if($('input[name="ustid"]').data("required") == "1") {
            $('input[name="ustid"]').prop("disabled", true);
          }
          if($('input[name="webseite"]').data("required") == "1") {
            $('input[name="webseite"]').prop("disabled", true);
          }
        }
      })
      .change();
    /*WENN BEGINN ALS NEUKUNDE*/
    $('input[name="kundennr"]').prop("required", false);
    $(".bestandskunde").toggle();

    /*************************************************************************
CHECKOUT - LIEFERANSCHRIFT FELDER
*************************************************************************/
  $("input[type='checkbox'][name='lieferadresse_question']").on("change", function () {
    if(this.checked) {
      if($('input[name="LFA_20_30"]').data("required") == "1") {
        $('input[name="LFA_20_30"]').prop("disabled", false);
      }
      if($('input[name="LFA_50_30"]').data("required") == "1") {
        $('input[name="LFA_50_30"]').prop("disabled", false);
      }
      if($('input[name="LFA_1392_30"]').data("required") == "1") {
        $('input[name="LFA_1392_30"]').prop("disabled", false);
      }
      if($('input[name="LFA_80_30"]').data("required") == "1") {
        $('input[name="LFA_80_30"]').prop("disabled", false);
      }
      if($('input[name="LFA_1014_11"]').data("required") == "1") {
        $('input[name="LFA_1014_11"]').prop("disabled", false);
      }
      if($('input[name="LFA_110_10"]').data("required") == "1") {
        $('input[name="LFA_110_10"]').prop("disabled", false);
      }
      if($('input[name="LFA_120_30"]').data("required") == "1") {
        $('input[name="LFA_120_30"]').prop("disabled", false);
      }
      if($('select[name="LFA_300_3"]').data("required") == "1") {
        $('select[name="LFA_300_3"]').prop("disabled", false);
      }
      if($('input[name="LFA_1202_14"]').data("required") == "1") {
        $('input[name="LFA_1202_14"]').prop("disabled", false);
      }
      if($('input[name="LFA_1332_60"]').data("required") == "1") {
        $('input[name="LFA_1332_60"]').prop("disabled", false);
      }
      if($('input[name="LFA_222_20"]').data("required") == "1") {
        $('input[name="LFA_222_20"]').prop("disabled", false);
      }
    } else {
      if($('input[name="LFA_20_30"]').data("required") == "1") {
        $('input[name="LFA_20_30"]').prop("disabled", true);
      }
      if($('input[name="LFA_50_30"]').data("required") == "1") {
        $('input[name="LFA_50_30"]').prop("disabled", true);
      }
      if($('input[name="LFA_1392_30"]').data("required") == "1") {
        $('input[name="LFA_1392_30"]').prop("disabled", true);
      }
      if($('input[name="LFA_80_30"]').data("required") == "1") {
        $('input[name="LFA_80_30"]').prop("disabled", true);
      }
      if($('input[name="LFA_1014_11"]').data("required") == "1") {
        $('input[name="LFA_1014_11"]').prop("disabled", true);
      }
      if($('input[name="LFA_110_10"]').data("required") == "1") {
        $('input[name="LFA_110_10"]').prop("disabled", true);
      }
      if($('input[name="LFA_120_30"]').data("required") == "1") {
        $('input[name="LFA_120_30"]').prop("disabled", true);
      }
      if($('select[name="LFA_300_3"]').data("required") == "1") {
        $('select[name="LFA_300_3"]').prop("disabled", true);
      }
      if($('input[name="LFA_1202_14"]').data("required") == "1") {
        $('input[name="LFA_1202_14"]').prop("disabled", true);
      }
      if($('input[name="LFA_1332_60"]').data("required") == "1") {
        $('input[name="LFA_1332_60"]').prop("disabled", true);
      }
      if($('input[name="LFA_222_20"]').data("required") == "1") {
        $('input[name="LFA_222_20"]').prop("disabled", true);
      }
    }
  });

    /*************************************************************************
LOGIN - LABEL UND INPUT ANIMATION
*************************************************************************/
    $(".loginform input").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".loginform input").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    //$(function () {
    setTimeout(function () {
      $(".loginform .widget").each(function () {
        var $input = $(this).find("input");
        if ($input.val()) {
          $input.parent().find("label").addClass("active");
        }
      });
    }, 100); //hold the code execution for 100 ms
    //});

    /*************************************************************************
PASSWORT ZURÜCKSETZEN - LABEL UND INPUT ANIMATION
*************************************************************************/
    $(".passwort-zuruecksetzen input").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".passwort-zuruecksetzen input").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    //$(function () {
    setTimeout(function () {
      $("#pwback .widget").each(function () {
        var $input = $(this).find("input");
        if ($input.val()) {
          $input.parent().find("label").addClass("active");
        }
      });
    }, 100); //hold the code execution for 100 ms
    //});

    /*************************************************************************
KONTAKTFORMULAR - LABEL UND INPUT ANIMATION
*************************************************************************/
    $(".kontakt input").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".kontakt input").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    $(".kontakt textarea").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".kontakt textarea").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    setTimeout(function () {
      $("#kontakt .widget").each(function () {
        var $input = $(this).find("input");
        if ($input.val()) {
          $input.parent().find("label").addClass("active");
        }
      });
    }, 100);

    /*************************************************************************
KONTAKTFORMULAR - CHECK CHECKBOX VALID
*************************************************************************/
    $("#kontakt input#submit").on("click", function () {
      var $checkbox = $("input#datenschutz");
      if ($checkbox.is(':checked')) {
        $checkbox.parent().removeClass("invalid");
      } else {
        $checkbox.parent().addClass("invalid");
      }
    });

    $("#kontakt input#datenschutz").on("click", function () {
      var $checkbox = $("input#datenschutz");
      if ($checkbox.is(':checked')) {
        $checkbox.parent().removeClass("invalid");
      } else {
        $checkbox.parent().addClass("invalid");
      }
    });

    /*************************************************************************
Produktliste - Switch Template
*************************************************************************/
    $("#grid").click(function () {
      showGrid();
    });
    $("#list").click(function () {
      showList();
    });
    $("#table").click(function () {
      showTable();
    });
    function showTable() {
      $(".table-view").addClass("show");
      $("#table").addClass("active");
      $("#list").removeClass("active");
      $("#grid").removeClass("active");
      $(".list-view").removeClass("show");
      $(".grid-view").removeClass("show");
    }
    function showGrid() {
      $(".grid-view").addClass("show");
      $("#grid").addClass("active");
      $("#list").removeClass("active");
      $("#table").removeClass("active");
      $(".list-view").removeClass("show");
      $(".table-view").removeClass("show");
    }
    function showList() {
      $(".list-view").addClass("show");
      $("#list").addClass("active");
      $("#grid").removeClass("active");
      $("#table").removeClass("active");
      $(".grid-view").removeClass("show");
      $(".table-view").removeClass("show");
    }

    /*************************************************************************
Produktliste - Filter Select Menu
*************************************************************************/
    // bind change event to select
    $("#prod_filter_select").on("change", function () {
      var url = $(this).val(); // get selected value
      if (url) {
        // require a URL
        //console.log(url);
        window.location = url; // redirect
      }
      return false;
    });
    $("#prod_filter_select_down").on("change", function () {
      var url = $(this).val(); // get selected value
      if (url) {
        // require a URL
        //console.log(url);
        window.location = url; // redirect
      }
      return false;
    });
    $("#count_filter_select").on("change", function () {
      var url = $(this).val(); // get selected value
      if (url) {
        // require a URL
        window.location = url; // redirect
      }
      return false;
    });
    $("#count_filter_select_down").on("change", function () {
      var url = $(this).val(); // get selected value
      if (url) {
        // require a URL
        window.location = url; // redirect
      }
      return false;
    });

    /*************************************************************************
MINUS / PLUS AUF INPUT
*************************************************************************/
    $(".qtnty-minus").click(function () {
      var $input = $(this).parent().find("input#menge");
      var count = parseInt($input.val()) - 1;
      count = count < 1 ? 1 : count;
      $input.val(count);
      $input.change();
      return false;
    });
    $(".qtnty-plus").click(function () {
      var $input = $(this).parent().find("input#menge");
      var count = parseInt($input.val()) + 1;
      count = count < 1 ? 1 : count;
      $input.val(count);
      $input.change();
      return false;
    });

/*************************************************************************
Checkout - LABEL UND INPUT ANIMATION
*************************************************************************/
    $(".checkout-adress input").on("focusin", function () {
      $(this).parent().find("label").addClass("active");
    });

    $(".checkout-adress input").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    $("#select_lieferaddresse").on("change", function () {
      $("#edit_adr .widget").each(function () {
        var $input = $(this).find("input");
        if ($input.val()) {
          $input.parent().find("label").addClass("active");
        } else {
          $input.parent().find("label").removeClass("active");
        }
      });
    });

    setTimeout(function () {
      $(".checkout-adress .widget").each(function () {
        var $input = $(this).find("input");
        if ($input.val()) {
          $input.parent().find("label").addClass("active");
        }
      });
    }, 100);
    
    $(".checkout-pruefen-bestellen input").on("focusin", function () {
      console.log("Focus in");
      $(this).parent().find("label").addClass("active");
    });

    $(".checkout-pruefen-bestellen input").on("focusout", function () {
      console.log("Focus out");
      if (!this.value) {
        $(this).parent().find("label").removeClass("active");
      }
    });

    setTimeout(function () {
      $(".checkout-pruefen-bestellen .widget").each(function () {
        var $input = $(this).find("input");
        if ($input.val()) {
          $input.parent().find("label").addClass("active");
        }
      });
    }, 100);

/*************************************************************************
Meine Bestellungen - Toggle
*************************************************************************/
    function toggle_visibility(id) {
      var e = document.getElementById(id);
      var p = document.getElementsByClassName(id);
      if (e.style.display == "block") e.style.display = "none";
      else e.style.display = "block";
    }

/*************************************************************************
Suche Lade Animation
*************************************************************************/
  $('#search_param').submit(function(){
    showWait();
  })

  var cartCounter=$('count-wk');
  if(cartCounter) {
    shopApi.updateCartCounter();
  }

  var respcartCounter=$('resp-count-wk');
  if(respcartCounter) {
    shopApi.updateCartCounter();
  }

  var wkitems=$('wk-items');
  if(wkitems) {
    shopApi.updateCartCounter();
  }

  } //end Jq Load
);

//auserhalb JQ loaded
//
// Variante - Produktdetailansicht
var langArray = [];
$(".var_picker option").each(function () {
  var img = $(this).attr("data-thumbnail");
  var text = $(this).attr("data-variantname");
  var variantprice = $(this).attr("data-price");
  var option = $(this).attr("data-option");
  var value = $(this).val();
  //console.log($(".var_picker")[0].selectedIndex);
  if(typeof variantprice !== "undefined") {
  var item =
        '<li data-url="' +
        $(this).attr("data-url") +
        '"><span class="left-side">' +
        '<span class="img"><img src="' +
        img +
        '" alt="" value="' +
        value +
        '" loading="lazy" decoding="async"/></span><span class="txt">' +
        text +
        '</span></span><span class="right-side">' +
        '<span class="variant_price">' +
        variantprice +
        " € / " +
        '</span>' +
        '<span class="art-nr">' +
        option +
        '</span><span><i class="fas fa-chevron-down"></i></li>';
  } else {
    var item =
        '<li data-url="' +
        $(this).attr("data-url") +
        '"><span class="left-side">' +
        '<span class="img"><img src="' +
        img +
        '" alt="" value="' +
        value +
        '" loading="lazy" decoding="async"/></span><span class="txt">' +
        text +
        '</span></span><span class="right-side">' +
        '<span class="art-nr">' +
        option +
        '</span><span><i class="fas fa-chevron-down"></i></li>';
  }
  langArray.push(item);
});

$("#a").html(langArray);

if (typeof $(".var_picker")[0] != "undefined") {
  //Set the button value to the first el of the array
  $(".btn-select").html(langArray[$(".var_picker")[0].selectedIndex]);
  $(".btn-select").attr(
    "value",
    $(langArray[$(".var_picker")[0].selectedIndex]).find("img").attr("value")
  );
}

/* Warten Dialog entfernen
 */
window.onpageshow = function(event) {
  if (event.persisted) {
    window.hideWait();
  }
};